import { gsap } from "gsap";
import { ScrollTrigger } from "gsap/ScrollTrigger";

gsap.registerPlugin(ScrollTrigger);

document.addEventListener("DOMContentLoaded", function () {
	/*
	--------------------------------
	Intro banner
	--------------------------------
	*/
	let introTL = gsap.timeline({
		scrollTrigger: {
			trigger: ".main-banner",
			pin: true,
			scrub: 1,
			start: "top top",
			end: "bottom",
			// markers: true,
		},
	});

	gsap.set(".main-banner .poster", { opacity: 0, scale: 1.2 });

	introTL.to(".main-banner .poster", { opacity: 1, duration: 7, scale: 1 });

	/*
	--------------------------------
	Scrolling marquee
	--------------------------------
	*/
	let direction = 1; // 1 = forward, -1 = backward scroll

	const roll1 = roll(".rollingText", { duration: 10 }),
		roll2 = roll(".rollingText02", { duration: 10 }, true),
		scroll = ScrollTrigger.create({
			onUpdate(self) {
				if (self.direction !== direction) {
					direction *= -1;
					gsap.to([roll1, roll2], {
						timeScale: direction,
						overwrite: true,
					});
				}
			},
		});

	// helper function that clones the targets, places them next to the original, then animates the xPercent in a loop to make it appear to roll across the screen in a seamless loop.
	function roll(targets, vars, reverse) {
		vars = vars || {};
		vars.ease || (vars.ease = "none");
		const tl = gsap.timeline({
				repeat: -1,
				onReverseComplete() {
					this.totalTime(this.rawTime() + this.duration() * 10); // otherwise when the playhead gets back to the beginning, it'd stop. So push the playhead forward 10 iterations (it could be any number)
				},
			}),
			elements = gsap.utils.toArray(targets),
			clones = elements.map((el) => {
				let clone = el.cloneNode(true);
				el.parentNode.appendChild(clone);
				return clone;
			}),
			positionClones = () =>
				elements.forEach((el, i) =>
					gsap.set(clones[i], {
						position: "absolute",
						overwrite: false,
						top: el.offsetTop,
						left:
							el.offsetLeft +
							(reverse ? -el.offsetWidth : el.offsetWidth),
					})
				);
		positionClones();
		elements.forEach((el, i) =>
			tl.to([el, clones[i]], { xPercent: reverse ? 100 : -100, ...vars }, 0)
		);
		window.addEventListener("resize", () => {
			let time = tl.totalTime(); // record the current time
			tl.totalTime(0); // rewind and clear out the timeline
			positionClones(); // reposition
			tl.totalTime(time); // jump back to the proper time
		});
		return tl;
	}

	/*
	--------------------------------
	Intro comunidades
	--------------------------------
	*/
	const num1 = document.querySelector(
		".introduccion .block1 .item .caption .title em"
	);

	gsap.to(num1, {
		scrollTrigger: num1,
		duration: 5,
		innerHTML: "1600",
		roundProps: "innerHTML",
		onUpdate: function () {
			num1.innerHTML = parseInt(num1.innerHTML).toLocaleString();
		},
	});

	const num2 = document.querySelector(
		".introduccion .block2 .item .caption .title em"
	);

	gsap.to(num2, {
		scrollTrigger: num2,
		duration: 5,
		innerHTML: "156600",
		roundProps: "innerHTML",
		onUpdate: function () {
			num2.innerHTML = parseInt(num2.innerHTML).toLocaleString();
		},
	});

	/*
	--------------------------------
	Frase Quote
	--------------------------------
	*/
	const frase = document.querySelector(".quote-convivencia .frase .txt span");

	let fraseTL = gsap.timeline({
		scrollTrigger: {
			trigger: ".quote-convivencia",
			// pin: true,
			scrub: 1,
			start: "bottom bottom",
			end: "center center",
			// markers: true,
			ease: "none",
		},
	});

	fraseTL.to(frase, { width: "100%" });

	/*
	--------------------------------
	Random elements
	--------------------------------
	*/
	// (function shuffleCards() {
	// 	let container = document.querySelector(".portafolio .blocks");
	// 	let cards = container.querySelectorAll(".block");

	// 	[...cards]
	// 		.map((node) => container.removeChild(node))
	// 		.sort(() => Math.random() - 0.5)
	// 		.forEach((node) => container.appendChild(node));
	// })();
});
